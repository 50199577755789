import React, {useEffect, useRef, useState} from 'react';
import styles from "../../Services/styles/Services.module.css";


const mockServices = [
    {
        id: 1,
        title: 'Послуги білінгу постачальникам житлових та комунальних послуг',
        subTitleForFirstP: 'Здійснення щомісячно нарахування плати за житлово-комунальні та інші послуги мешканцям Замовника згідно інформації та документів, наданих Замовником, що включає:',
        subTitleForSecondP: 'Виконання робіт з організації збору даних, актуалізації інформації, внесення інформації до бази даних, забезпечує отримання інформації від споживачів послуг із подальшим внесенням змін до бази даних.',
        descriptionServicesFirstP: 'введення нових та вилучення застарілих особових рахунків; несення змін щодо зареєстрованих осіб; внесення показників з квартирних приладів обліку та обрахунок кількості спожитих комунальних послуг на особових рахунках споживачів; виконання перерахунків вартості нарахованих послуг за минулий період за зверненнями та листами Замовника; нарахування комунальних та інших послуг; формування оборотної відомості нарахування комунальних та інших послуг в розрізі послуг; формування оборотної відомості нарахування комунальних та інших послуг в розрізі особових рахунків; подання даних оборотної відомості нарахування комунальних та інших послуг в розрізі особових рахунків споживачів послуг для друку в Єдиному Рахунку за надані житлово-комунальні та інші послуги',
        descriptionServicesSecondP: "Розробка процесів та процедур актуалізації існуючих даних;  Розробка каналів комунікації із споживачами для отримання зворотного зв'язку;  Розробка процесів внесення змін, виправлень та доповнень в базу даних",
    },
    {
        id: 2,
        title: 'Обробка даних',
        subTitleForFirstP: 'Використовуючи власні ресурси дата-центру ми надаємо наступні послуги:',
        subTitleForSecondP: 'Здійснення щоденно централізованої обробки прийнятих платежів за житлово-комунальні послуги:',
        descriptionServicesFirstP: 'Здійснення щоденно централізованої обробки прийнятих платежів за житлово-комунальні ' +
            'та інші послуги згідно даних банківських та фінансових установ міста, відділень поштового зв’язку, тощо,' +
            ' та надання фінансовим установам інформації про суми, які підлягають перерахуванню на користь Замовника;' +
            'Формування зведеного реєстру прийнятих платежів за житлово-комунальні' +
            'розрізі місяців) та надання інформації, яка зазначена в зведеному реєстрі на' +
            'електронну адресу Замовника',
        descriptionServicesSecondP: 'Підтримка бази даних споживачів послуг Замовника в пунктах прийому платежів в належному стані ' +
            'Формування реєстру заборгованості споживачів Замовника в розрізі їх' +
            'особових рахунків;' +
            'Надання Замовнику щомісяця, до 3-го числа, зведену інформацію про платежі, прийняті фінансовими установами за житлово-комунальні та інші послуги за попередній місяць по кожномуособовому рахунку споживача Замовника;' +
            'Рознесення оплат, прийняту фінансовими установами міста на особові' +
            'рахунки споживачів Замовника (щоденно);' +
            'Підтримка бази даних споживачів послуг Замовника в пунктах прийому платежів в належному стані',
    },
    {
        id: 3,
        title: 'Робота з споживачами',
        subTitleForFirstP: 'Допомагаємо споживачам оформити зміну власника помешкання:',
        subTitleForSecondP: 'Прийом показників лічильників:',
        descriptionServicesFirstP: 'Наші фахівці \n' +
            'завжди готові надати консультації щодо оплати, змін тарифів та інших питань\n;' +
            'Прийом показників лічильників: забезпечуємо збір та обробку показників \n' +
            'лічильників від споживачів. Це може бути здійснено шляхом фізичного \n' +
            'відвідування Центру Обслуговування  або за допомогою дистанційного збору \n' +
            'показників з використанням  чат- ботів, мобільного застосунку та сайту;\n' +
            'Для зручності наших клієнтів ми проводимо виїзні консультації до прозорих \n' +
            'офісів;\n' +
            'Оплата комунальних послуг: надаємо можливість споживачам оплачувати \n' +
            'послуги через нашу систему;\n' +
            'Контроль та звітність: забезпечуємо контроль за платежем та звітність \n' +
            'споживачам;\n' +
            ' Зручність та доступність: пропонуємо зручні способи оплати та можливість \n' +
            'отримувати інформацію в будь-який час за допомогою онлайн-системи. Ви \n' +
            'можете зв\'язатися з нами за допомогою онлайн-чату, телефону або електронної \n' +
            'пошти; \n' +
            ' Професійний підхід: Наша команда має великий досвід у сфері нарахування та \n' +
            'оплати комунальних послуг. Ми забезпечуємо якість та надійність наших \n' +
            'послуг, враховуючи індивідуальні потреби і вимоги кожного клієнта',
        descriptionServicesSecondP: 'забезпечуємо збір та обробку показників \n' +
            'лічильників від споживачів. Це може бути здійснено шляхом фізичного \n' +
            'відвідування Центру Обслуговування  або за допомогою дистанційного збору \n' +
            'показників з використанням  чат- ботів, мобільного застосунку та сайту; Для зручності наших клієнтів ми проводимо виїзні консультації до прозорих офісів',
    },
    {
        id: 4,
        title: 'Друк рахунків та інформаційних повідомлень',
        subTitleForFirstP: 'Друк, формування та доставка Єдиних рахунків',
        descriptionServicesFirstP: 'Щомісячним   підсумком   роботи   ТОВ   «Центр   муніципальних   систем\n' +
            'управління»  є формування, друк та доставка Єдиних рахунків до поштових\n' +
            'скриньок споживачів. Сучасні кольорові принтери підприємства забезпечують\n' +
            'якісний друк зі швидкістю 120 сторінок за хвилину. Також наявні  виробничі\n' +
            'потужності для конвертування, перфорації та проклеювання рахунків та інших\n' +
            'інформаційних   повідомлень.  Тираж   185   тисяч   рахунків   друкується     та\n' +
            'конвертується протягом двох календарних днів. ТОВ «Центр муніципальних\n' +
            'систем управління» виконує друк та конвертацію  рахунків та інформаційних\n' +
            'повідомлень підприємствам з інших регіонів',
        descriptionServicesSecondP:'$'
    },
    {
        id: 4,
        title: 'Робота з ОСББ',
        descriptionServicesFirstP: 'Ведення бази даних особових рахунків; включає збір та обробку інформації; підтримка актуальних даних; безпечне зберігання даних;формування звітності;Здійснення   нарахувань   –   надійний   спосіб   здійснення   автоматичного процесу нарахування платежів по послугах ОСББ, контроль оплат, що дозволить   знизити   ризик   помилок   та   підвищити   ефективність управлінні будинком;Організація прийому платежів банківськими та фінансовими установами –  надійний   централізований   механізм   для   спрощення   та   оптимізації процесу прийому грошових коштів від співвласників ОСББ; Надання   програмного   забезпечення   –   індивідуальний   підхід   до\n' +
            'задоволення   ваших   потребу   програмних   рішеннях. Ми надаємо програмне   забезпечення  proOSBB,   яке   дозволить   підтримувати актуальність   даних   будинку,   автоматизувати   всі   процеси,   підвищити продуктивність діяльності ОСББ; Консультаційна підтримка ОСББ – надає індивідуальні консультації та підтримку, щоб допомогти вам вирішити проблеми та отримати рішення',
        descriptionServicesSecondP: '$',
    },
    {
        id: 6,
        title: 'Платіжна платформа',
        subTitleForFirstP: 'ТОВ «Центр муніципальних систем управління» створено розгалужену систем прийому   платежів   за   житлово-комунальні   та   інші   послуги.   Всі   учасникисистеми працюють в єдиному інформаційному просторі та отримують доступдо   уніфікованої   територіальної   бази   даних.   Платник   для   здійснення   оплатиможе обрати:',
        descriptionServicesFirstP: '“касове вікно” (банки, Укрпошта, фінансові компанії); платіжні термінали; інтернет-сервіс PAY.VN.UA; мобільний додаток Єдиний Рахунок; Система дозволяє  коригування залишків по послугах платників режимі online а\n' +
            'постачальники   послуг   можуть   відслідковувати   оплату   своїх   абонентів   в\n' +
            'реальному часі',
         descriptionServicesSecondP: '$',
    },
];

export const ServicesSection = () => {
    const [activeItem, setActiveItem] = useState(null);
    const [animationActive, setAnimationActive] = useState(false);
    const headerRef = useRef(null);
    const handleItemClick = (index) => {
        setActiveItem(index);
    };

    useEffect(() => {
        if (window.innerWidth <= 900 && headerRef && headerRef.current) {
            headerRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [activeItem]);

    useEffect(() => {
        setAnimationActive(true)
        const timeout = setTimeout(() => {
            setAnimationActive(false)
        }, 500)
        return () => clearTimeout(timeout)
    }, [activeItem, activeItem])

    return (
        <section className={`${styles.servicesContainer} ${animationActive ? styles.animate : ''}`}>
            <nav className={styles.aside}>
                <ul>
                    {mockServices.map((item, index) => {
                        return (
                            <li key={index}
                                onTouchEnd={() => handleItemClick(index)}
                                className={index === activeItem ? styles.activeNav : styles.nav}
                                onClick={() => handleItemClick(index)}>{item.title}
                            </li>
                        )
                    })}
                </ul>
            </nav>
            <article ref={headerRef} className={`${styles.article}`}>
                <h2 className={styles.headerServiceTitle}>
                    {
                        activeItem
                            ?
                            mockServices[activeItem].title
                            :
                            mockServices[0].title
                    }
                </h2>
                <div className={styles.descriptionServicesSecondPContainer}>
                    <h4 className={styles.subTitleForFirstP}>
                        {
                            activeItem ?
                                mockServices[activeItem].subTitleForFirstP
                                :
                                mockServices[0].subTitleForFirstP
                        }
                    </h4>
                    <div>
                        {activeItem && mockServices[activeItem].descriptionServicesFirstP ?
                            mockServices[activeItem].descriptionServicesFirstP.split(';').map(function (item, index) {
                                return (
                                    <p key={index} className={styles.descriptionServicesFirstP}>
                                        <span>•</span> {item}.
                                    </p>
                                );
                            })
                            :
                            mockServices[0].descriptionServicesFirstP.split(';').map(function (item, index) {
                                return (
                                    <p key={index} className={styles.descriptionServicesFirstP}>
                                        <span>•</span> {item}.
                                    </p>

                                );
                            })
                        }
                    </div>
                </div>
                <div className={styles.descriptionServicesSecondPContainer}>
                    <h4 className={styles.subTitleForFirstP}>
                        {activeItem ?
                            mockServices[activeItem].subTitleForSecondP
                            :
                            mockServices[0].subTitleForSecondP
                        }
                    </h4>
                    <div>
                        {activeItem && mockServices[activeItem].descriptionServicesSecondP ?
                            mockServices[activeItem].descriptionServicesSecondP.split(';').map(function (item, index) {
                                if (item.trim().split('$').length === 2) {
                                    return null; // Возвращаем null для пустой строки
                                } else {
                                    return (
                                        <p key={index} className={styles.descriptionServicesFirstP}>
                                            <span>•</span> {item}.
                                        </p>
                                    );
                                }
                            })
                                :
                                mockServices[0].descriptionServicesSecondP.split(';').map(function (item, index) {
                                    return (
                                        <p key={index} className={styles.descriptionServicesFirstP}>
                                            <span>•</span> {item}.
                                        </p>
                                    );
                                })
                        }
                    </div>
                </div>
            </article>
        </section>
    )
}



