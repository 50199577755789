import React, {useEffect, useState} from "react";
import styles from '../styles/Main.module.css'
import phoneIcon from '../assets/phone_icon.png'
import letter_icon from '../assets/letter_icon.png'
import addressIcon from '../assets/address_icon.png'
import {SERVER} from "../../../constants/constants";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Modal} from "../../../components/Modal/Modal";

export const FeedbackFormSection = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isValidName, setIsValidName] = useState(false)
    const [isValidEmail, setIsValidEmail] = useState(false)
    const [isValidMessage, setIsValidMessage] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDisabled,setIsDisabled] = useState(false)
    useEffect(() => {
        if (email.length) {
            if (email === '' || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) === false) {
                setIsValidEmail(true)
            } else {
                setIsValidEmail(false)
                setIsDisabled(false)
            }
        } else if (name.length) {
            if (name === '') {
                setIsValidName(true)
            } else {
                setIsValidName(false)
            }
        } else if (message.length) {
            if (message === '') {
                setIsValidMessage(true)
            } else {
                setIsValidMessage(false)
            }
        }

    }, [email, name, message])

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);

    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (name === '') {
            setIsValidName(true)
        } else {
            setIsValidName(false)
        }
        if (email === '' || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) === false) {
            setIsValidEmail(true)
        } else {
            setIsValidEmail(false)
        }
        if (message === '') {
            setIsValidMessage(true)
        } else {
            setIsValidMessage(false)
        }
        if (isValidEmail === true) {
            toast.error(`Невірний формат пошти`, {
                position: "bottom-center",
                autoClose: 500,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                closeButton: false,
                className: `${styles.toastMessage}`,
            });
            setTimeout(() => {
                setIsDisabled(true)
            },100)
            return;
        }
            const data = {
            name: name,
            email: email,
            message: message,
            form_type: 'feedback'
        }
        fetch(`${SERVER}/forms`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "*/*",
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive"
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
         if (result.message) {
                    toast.success('Запит прийнято', {
                        position: "bottom-center",
                        autoClose: 500,
                        hideProgressBar: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        closeButton: false,
                        className: `${styles.toastMessageOK}`,
                    })
                } else if (result.error) {
                    toast.error(`Невірно введені дані`, {
                        position: "bottom-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        closeButton: false,
                        className: `${styles.toastMessage}`,
                    });
                }

            })
            .catch(error => {
                console.error('Error:', error);
            });
        cleanForm();
    }

    const cleanForm = () => {
        setName("");
        setEmail("");
        setMessage("");
    }

    return (
        <section className={styles.feedbackSection}>
            {isModalOpen && (
                <Modal title={'Зв’язатися'} comment={'Коментар'} onClose={closeModal} fromProjects/>
            )}
            <div className={styles.form}>
                <form
                    onSubmit={handleSubmit}>
                    <h1>Форма для звернень</h1>
                    <label>
                        Ім'я <span>*</span>
                        <input
                            type="text"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            className={`${!isValidName ? styles.customInput : styles.errorBorder}`}
                        />
                    </label>
                    <label>
                        Електронна пошта <span>*</span>
                        <input
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            className={`${!isValidEmail ? styles.customInput : styles.errorBorder}`}
                        />
                    </label>
                    <label>
                        Коментар <span>*</span>
                        <textarea
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                            className={`${!isValidMessage ? styles.customTextarea : styles.errorBorder}`}
                        />
                    </label>
                    <div className={styles.btnContainerForm}>
                        <button  disabled={isDisabled} onClick={handleSubmit}>
                            Надіслати
                        </button>
                        <a onClick={openModal} className={styles.orderCall}>або замовити дзвінок</a>
                    </div>
                </form>
                <div className={styles.formFooter}>
                    <div className={styles.formFooterItem}>
                        <div className={styles.leftBlock}>
                            <img src={phoneIcon} alt={"phone"}/>
                        </div>
                        <div className={styles.rightBlock}>
                            <p className={styles.rightBlocktext}>
                                Телефон</p>
                            <a href='tel:+380432651155' className={styles.markSpan}>(0432) 65-11-55</a>
                        </div>
                    </div>
                    <div className={styles.formFooterItem}>
                        <div className={styles.leftBlock}>
                            <img src={letter_icon} alt={"post"}/>
                        </div>
                        <div className={styles.rightBlock}>
                            <p className={styles.rightBlocktext}>
                                Пошта</p>
                            <a href='mailto:info@cmsm.com.ua' className={styles.markSpan}>info@cmsm.com.ua</a>
                        </div>
                    </div>
                    <div className={styles.formFooterItem}>
                        <div className={styles.leftBlock}>
                            <img src={addressIcon} alt={"address"}/>
                        </div>

                        <div className={styles.rightBlock}>
                            <p className={`${styles.rightBlocktext}${styles.rightBlocktextLast}`}>
                                Адреса
                                <a href='https://www.google.com/maps/place/%D0%A2%D0%9E%D0%92+%22%D0%A6%D0%B5%D0%BD%D1%82%D1%80+%D0%BC%D1%83%D0%BD%D1%96%D1%86%D0%B8%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B8%D1%85+%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC+%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D1%96%D0%BD%D0%BD%D1%8F%22/@49.2346375,28.4423375,17z/data=!4m14!1m7!3m6!1s0x472d5c86812c7589:0x95a67822c37dccab!2z0KLQntCSICLQptC10L3RgtGAINC80YPQvdGW0YbQuNC_0LDQu9GM0L3QuNGFINGB0LjRgdGC0LXQvCDRg9C_0YDQsNCy0LvRltC90L3RjyI!8m2!3d49.2346375!4d28.4449124!16s%2Fg%2F1pp2vfcfk!3m5!1s0x472d5c86812c7589:0x95a67822c37dccab!8m2!3d49.2346375!4d28.4449124!16s%2Fg%2F1pp2vfcfk?authuser=0&entry=ttu'
                                   target="_blank" rel="noreferrer"> <span className={styles.markSpan}> м. Вінниця <br
                                    className={styles.br}/> вул. Антона Листопада, 7а</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.map}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2596.189803190478!2d28.442345272000548!3d49.23463819850382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472d5c86812c7589%3A0x95a67822c37dccab!2z0KLQntCSICLQptC10L3RgtGAINC80YPQvdGW0YbQuNC_0LDQu9GM0L3QuNGFINGB0LjRgdGC0LXQvCDRg9C_0YDQsNCy0LvRltC90L3RjyI!5e0!3m2!1sru!2sua!4v1700553190255!5m2!1sru!2sua"
                    width="600" height="100%" style={{border: 0}} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <ToastContainer/>
        </section>
    )
}
