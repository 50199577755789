import React, {useEffect, useState} from "react";
import styles from './styles/Main.module.css'
import {EntrySection} from "./modules/EntrySection";
import {ServiceSection} from "./modules/ServiceSection";
import {StatisticSection} from "./modules/StatisticSection";
import {MemoizedAboutUs} from "./modules/AboutUsSection";
import {PartnersSection} from "./modules/PartnersSection";
import {NewsSection} from "./modules/NewsSection";
import {FeedbackFormSection} from "./modules/FeedbackFormSection";
import {Footer} from "../../components/Footer/Footer";
import {Header} from "../../components/Header/Header";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
    duration: 3000,
})

export const Main = () => {
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 900);

    useEffect(() => {
        // window.scrollTo({top: 0, behavior: 'smooth'});

        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 900);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.container}>
            {!isWideScreen ? <Header/> : null}
            <EntrySection/>
            <ServiceSection/>
            <StatisticSection/>
            <MemoizedAboutUs/>
            <PartnersSection/>
            <NewsSection/>
            <FeedbackFormSection/>
            <Footer/>
        </div>
    )
}
