import React from "react";

import businessCenter from '../../../assets/modern-business-building-scenery-touching-sky.png'
import businessTeam from '../../../assets/business-team-manager-meeting.png'
import businessMeeting from '../../../assets/business-team-manager-meeting2.png'
import cmsuCap from '../../../assets/cmsucap.png'
import styles from '../styles/AboutUs.module.css'

export const AboutUsSection = () => {

    return (
        <article className={styles.aboutUsContainer}>
            <div className={styles.mobileContainer}>
                <section className={styles.sectionContainer}>
                    <div className={styles.containerImgBusinessCenter}>
                        <img className={styles.imgBusinessCenter} src={businessCenter} alt="busines center"/>
                    </div>
                    <div className={`${styles.aboutDescriptionContainer} ${styles.aboutDescriptionContainerFirst}`}>
                        <div className={styles.aboutDescriptionContainerInner}>
                            <h1 className={styles.title}>Центр муніципальних систем управління</h1>
                            <p className={`${styles.aboutDescription} ${styles.aboutDescriptionFirstP}`}>
                                "Центр муніципальних систем управління" розпочав свій шлях у кінці 2012 року та невпинно
                                працює над вдосконаленням своїх проєктів. Ми впроваджуємо передові технології для
                                поліпшення управління житловим простором та фінансовими операціями, прагнучи надати
                                високоякісні послуги.
                            </p>
                            <div className={styles.imgBusinessTeamContainer}>
                                <img className={styles.imgBusinessTeam} src={businessTeam} alt="busines team"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={`${styles.sectionContainer} ${styles.sectionContainerAdaptiv}`}>
                    <div className={styles.imgBusinessMeetingContainer}>
                        <img className={styles.imgBusinessMeeting} src={businessMeeting} alt="busines meeting"/>
                    </div>
                    <div className={styles.aboutDescriptionContainer}>
                        <p className={`${styles.aboutDescription} ${styles.aboutDescriptionExtraMargin}`}>

                            Проєкт <a className={styles.alwaysBlue} href="https://proosbb.com/" target='blank'>proOSBB</a> є
                            результатом наших наполегливих зусиль у
                            вдосконаленні. <a className={styles.alwaysBlue} href="https://pay.vn.ua/" target='blank'>Сервіс
                            Pay</a>, який включає вебресурс та мобільний додаток "Єдиний рахунок", став важливим кроком
                            у спрощенні та полегшенні фінансових операцій для наших користувачів. Проєкт <a className={styles.alwaysBlue}
                            href="https://pay.vn.ua/"
                            target='blank'>"Єдиний Рахунок"</a>, об'єднує різні сфери, включаючи споживачів,
                            постачальників, банки та
                            органи самоврядування, забезпечуючи зручний формат оплати та інформаційну прозорість для
                            всіх учасників. <a className={styles.alwaysBlue} href="https://pay.vn.ua/" target='blank'>Термінальна мережа</a> та можливість безготівкової оплати є
                            ще одним важливим
                            кроком у розвитку нашої компанії.
                        </p>
                    </div>
                </section>
                <section className={`${styles.sectionContainer} ${styles.sectionContainerAdaptiv}`}>
                    <div className={styles.finalAboutSection}>
                        <p className={`${styles.aboutDescription} ${styles.finalAboutSectionExtraMargin}`}>
                            Це лише початок нашої подорожі в майбутнє. Наша місія полягає в тому, щоб зробити управління
                            житловим простором та фінансовими операціями ще більш зручним та ефективним. Ми ретельно
                            вивчаємо потреби наших користувачів і реагуємо на зміни в сучасному житті, щоб надавати
                            інноваційні рішення, що відповідають їхнім очікуванням. Наше зобов'язання - працювати над
                            тим, щоб зробити життя наших клієнтів ще більш комфортним та зручним.
                        </p>
                    </div>
                    <div className={styles.imgcmsuCapContainer}>
                        <img className={styles.imgcmsuCap} src={cmsuCap} alt="cmsu cap"/>
                    </div>
                </section>
            </div>
        </article>
    )
}
