import React from 'react';

import styles from "./NavigationButton.module.css";

export const NavigationButton = ({buttonText, extraClass,onClick}) => {
    const buttonClasses = `${styles.navigateButton} ${extraClass || ''}`;
    return (
        <button onClick={onClick} className={buttonClasses} type='submit'>
            {buttonText}
        </button>
    );
}
