import styles from "../styles/Main.module.css";
import React from "react";
import {ServicesSlider} from "../components/ServicesSlider";

export const ServiceSection = () => {
    return (
        <section className={styles.serviceContainer}>
            <h1 className={styles.serviceTitle}>Перелік надання послуг</h1>
            <ServicesSlider />
        </section>
    )
}
