import { create } from'zustand'
import {SERVER} from "../../../constants/constants";

export const useMainStore = create((set) => ({
    news: [],
    fetchNews: async () => {
        const response = await fetch(`${SERVER}/blogs`)
        set({ news: await response.json() })
    },
}))
