import React from 'react';
import styles from './NavigationTop.module.css'
import {Link} from "react-router-dom";
import {useSelectedNewsStore} from "../../pages/News/store/newsStore";

export const NavigationTop = ({text,onClick,}) => {
    const {  setSelectedNews } = useSelectedNewsStore();

    return (
        <div className={styles.container}>
            <Link to={'/'} onClick={()=>setSelectedNews(null)}  className={styles.link}>
                Головна
            </Link>
            <h3 onClick={onClick} className={styles.pageName}>{text}</h3>
        </div>
    );
}
