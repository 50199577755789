import styles from '../styles/Careers.module.css'

import noVacancy from '../../../assets/noVacancy.png'
import {NavigationButton} from "../../../components/NavigationButton/NavigationButton";
import React from "react";
export const NoVacancy = ({openModal}) => {
    return (
        <div className={styles.containerNoVacancy}>
            <div className={styles.imgNoVacancy}>
                <img src={noVacancy} alt="NoVacancy"/>
            </div>
            <div className={styles.descriptionNoVacancy}>
                <h3 className={styles.titleNoVacancy}>На жаль, вакансії відсутні зараз!</h3>
                <p className={styles.textNoVacancy}>Наразі у нас немає актуальних вакансій. Залиште свої дані та резюме, і ми розглянемо вашу кандидатуру. Дякуємо за розуміння та інтерес до нашої компанії!</p>
                <div><NavigationButton onClick={()=>openModal()} extraClass={styles.navigationButtonNoVacancy}
                                       buttonText={'Подати заявку'}/> </div>

            </div>
        </div>
    )
}