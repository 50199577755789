import React, {useEffect} from "react";
import {Header} from "../../components/Header/Header";
import {AboutUsSection} from "./modules/AboutUsSection";
import styles from "./styles/AboutUs.module.css";
import {Footer} from "../../components/Footer/Footer";
import {NavigationTop} from "../../components/NavigationTop/NavigationTop";
import {PathNames} from "../../navigation/pathNames";
import {AdditionalNavigation} from "../../components/AdditionalNavigation/AdditionalNavigation";
import {useNavigate} from "react-router-dom";

function AboutUs() {
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
            <Header isBorderShow={true}/>
                </div>
            <div className={styles.navigationTopContainer}>
                <NavigationTop text={'Про компанію'}/>
                </div>
            <AboutUsSection/>
            <div className={styles.addNavigation}>
            <AdditionalNavigation
                onClick={() => {
                    navigate(PathNames.Careers)
                }}
                buttonText={"Переглянути"}
                titleText={"Хочеш стати частиною команди?"}
                spanText={"Дивись вакансії"}/>
            </div>
            <Footer/>
        </div>
    )
}

export const MemoizedAboutUs = React.memo(AboutUs)
