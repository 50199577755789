export function truncateText(text, maxLength = 200) {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.substring(0, maxLength - 3) + "...";
    }
}

export const validatePhone = (val) => {
    const remove = val
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);

    return "+" +
        remove
            .slice(1, 6)
            .filter((item) => item !== "")
            .join("-")
            .replace(/(\d{3})-(\d{2})-(\d{3})-(\d{2})-(\d{2})/, "$1-$2-$3-$4-$5");
};

export const formattedDate = (dateNews) => {
    const originalDate = new Date(dateNews);

    const day = originalDate.getDate();
    const month = originalDate.getMonth() + 1; // Months are zero-based, so add 1
    const year = originalDate.getFullYear();

    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

}

export const formatArticleText = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const paragraphs = text.split('\n');

    return paragraphs.map((paragraph, index) => {
        const formattedParagraph = paragraph.replace(urlRegex, (url) => {
            return `<a href="${url}" target="_blank" style="word-break: break-all;">${url}</a>`;
        });

        return (
            <p key={index} dangerouslySetInnerHTML={{__html: formattedParagraph}}/>
        );
    });
};
