import React, {useEffect, useState} from "react";
import styles from './News.module.css'
import {Header} from "../../components/Header/Header";
import {NavigationTop} from "../../components/NavigationTop/NavigationTop";
import {Footer} from "../../components/Footer/Footer";
import {NewsGridSection} from "./modules/NewsGridSection";
import {Pagination} from "./components/Pagination";
import {useMainStore} from "../Main/store/mainStore";
import {useSelectedNewsStore} from "./store/newsStore";


export const News = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [newsPerPage, setNewsPerPage] = useState(6);
    const { selectedNews,setSelectedNews } = useSelectedNewsStore();

    const { news, fetchNews } = useMainStore();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        (async () => {
            await fetchNews();
        })();
    }, [fetchNews]);

    useEffect(() => {
        if (selectedNews) {
            setNewsPerPage(3);
        } else {
            setNewsPerPage(6);
        }
    }, [selectedNews]);

    const indexOfLastNews = currentPage * newsPerPage;
    const indexOfFirstNews = indexOfLastNews - newsPerPage;
    const reversedNews = news.slice().reverse();

    const currentNews = reversedNews.slice(indexOfFirstNews, indexOfLastNews);

    const paginate = (number) => {
        setCurrentPage(number);
    };

    return (
        <section className={styles.container}>
            <Header isBorderShow />
                <NavigationTop onClick={()=>setSelectedNews(null)} text={"Новини"} />
            <NewsGridSection news={currentNews} loading={false} />
            <span className={styles.line}></span>
            <Pagination newsPerPage={newsPerPage} totalNews={news.length} paginate={paginate} />
            <Footer />
        </section>
    );
}
