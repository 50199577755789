import styles from './DropDown.module.css'
import {useSelectedOptionStore} from "../Header/headerStore";
import {Link} from "react-router-dom";
import {PathNames} from "../../navigation/pathNames";

export const DropdownMenu = () => {
    const { setSelectedOption } = useSelectedOptionStore();
    return (
        <div>
            <ul className={styles.dropdownMenuContainer} >
                <li className={styles.dropdownMenuLi} onClick={()=>setSelectedOption('Єдиний Рахунок')}><Link to={PathNames.ProjectsER}>Єдиний Рахунок</Link></li>
                <li className={styles.dropdownMenuLi} onClick={()=>setSelectedOption('pay')}><Link to={PathNames.ProjectsPay}>Сервіс Pay</Link> </li>
                <li className={styles.dropdownMenuLi} onClick={()=>setSelectedOption('proOSBB')}><Link to={PathNames.ProjectsProOsbb}>proOSBB</Link></li>
                <li className={styles.dropdownMenuLi} onClick={()=>setSelectedOption('terminal')}><Link to={PathNames.ProjectsTerminal}>Термінал мережа</Link></li>
            </ul>
        </div>
    );
};
