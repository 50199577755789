import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {CarouselItem} from "./CarouselItem";
import {ButtonGroup} from "./ButtonGroup";
import styles from './Slider.module.css'

import billing from './assets/billing.png'
import communications from './assets/comminication.png'
import osbb from './assets/osbb.png'
import calcularor from './assets/calculator.png'
import people from './assets/people.png'
import fileP from './assets/fileP.png'

function getWindowSize() {
    if (typeof window !== 'undefined') {
        return window.innerWidth;
    } else {
        return 0;
    }
}
const screenSize = getWindowSize();

const responsive = {
    superLargeDesktop: {
        breakpoint: {max: 3000, min: 1700},
        items: 4
    },
    desktop: {
        breakpoint: {max: 1700, min: 1000},
        items: 3
    },
    tablet: {
        breakpoint: {max: 1000, min: 650},
        items: 2
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1.5
    }
};

const mockData = [
    {
        title: "Послуги білінгу постачання житлових та комунальних послуг",
        description: 'Ведення бази даних особових рахунків споживачів Замовника з \n' +
            'відображенням      інформації щодо нарахувань, обсягів споживання \n' +
            'послуг, оплати заборгованостей, технічних характеристик тощо.',
        img: billing
    },
    {
        title: "Обробка даних",
        description: 'Організація прийому платежів банківськими та фінансовими установами, \n' +
            'відділеннями поштового зв’язку, централізований збір інформації, \n' +
            'оброблення платежів від споживачів Замовника за надані житлово-\n' +
            'комунальні та інші послуги, з он-лайн відображенням по кожному \n' +
            'особовому рахунку.',
        img: osbb
    },
    {
        title: "Робота з споживачами",
        description: 'Надаємо консультації щодо нарахування  та оплати комунальних послуг, \n' +
            'забезпечуючи точне та оперативне обслуговування мешканців. \n' +
            'Задоволення потреб та очікувань споживачів, забезпечення якісного \n' +
            'обслуговування, збереження та розвиток клієнтської бази, підвищення \n' +
            'рівня комунікації з клієнтами та забезпечення їх лояльності.',
        img: communications
    },
    {
        title: "Друк рахунків та інформаційних повідомлень",
        description: 'Друк рахунків та інформаційних повідомлень важливий для забезпечення\n' +
            'чіткої комунікації між компанією та клієнтами. Це засіб надання \n' +
            'важливої інформації щодо послуг, термінів та інших аспектів співпраці, \n' +
            'сприяючи взаєморозумінню та відкритому відношенню.',
        img: calcularor
    },
    {
        title: "Платіжна платформа",
        description: 'Пропонує різноманітні шляхи оплати. Платники мають вибір, а \n' +
            'постачальники чи компанії можуть відстежувати оплату в режимі \n' +
            'реального часу. Оперативність проведення платежів дозволяє точно \n' +
            'відслідковувати сплату за надані послуги.',
        img: fileP
    },
    {
        title: "Робота з ОСББ",
        description: 'Індивідуальний підхід до кожного будинку та комплекс послуг, які \n' +
            'спрямовані на полегшення і покращення роботи ОСББ. Ми надаємо \n' +
            'комплексний підхід  до управління, включаючи нарахування оплати за \n' +
            'послуги.',
        img: people
    },
]

let itemsToShow;

if (mockData.length <= 1) {
    itemsToShow = 1; // Если у вас только один слайд, показываем только один элемент
} else if (screenSize >= responsive.superLargeDesktop.breakpoint.min) {
    itemsToShow = responsive.superLargeDesktop.items;
} else if (screenSize >= responsive.desktop.breakpoint.min) {
    itemsToShow = responsive.desktop.items;
} else if (screenSize >= responsive.tablet.breakpoint.min) {
    itemsToShow = responsive.tablet.items;
} else {
    itemsToShow = responsive.mobile.items;
}
const lastIndexValue = mockData.length - itemsToShow;

export const ServicesSlider = () => {
    const renderItems = mockData.map((item, index) => {
        const itemClass = index === 2 ? styles.customStyle : '';
        return <CarouselItem
            key={index}
            title={item.title}
            description={item.description}
            img={item.img}
            className={itemClass}
        />
    })

    return (
        <Carousel
            arrows={false}
            itemClass={styles.containerItem}
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup lastIndex={lastIndexValue}/>}
            responsive={responsive}>
            {mockData ? renderItems : <h1>Sorry</h1>}
        </Carousel>
    )
}
