import styles from './Slider.module.css'

export const CarouselItem = (props) => {
  const {title, description, img ,className} = props;

  return (
      <div className={styles.wrapper}>
         <div className={styles.titleContainer}>
             <h3 className={styles.itemTitle}>{title}</h3>
         </div>
          <div className={`${styles.descriptionContainer} ${className}`}>
              <p className={styles.itemDescription}>{description}</p>
          </div>
          <div className={styles.itemImg}>
              <img src={img} alt='service-icon'/>
          </div>
      </div>
  )
}
