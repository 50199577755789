import React from 'react';
import styles from './AdditionalNavigation.module.css';
import webCmsuRed from "../../assets/webCmsuRed.png";

export const AdditionalNavigation = ({buttonText, spanText, titleText, onClick}) => {
    return (
        <section className={styles.container}>
            <div className={styles.careersContainerInner}>
                <div className={styles.navigationInfoContainer}>
                    <h2 className={styles.navigationInfoText}>{titleText}<br/>
                        <span>{spanText}</span>
                    </h2>
                </div>
                <div>
                    <button onClick={onClick} className={styles.navigateButton} type='submit'>
                        {buttonText}
                    </button>
                </div>
            </div>
            <div className={styles.imgWebCmsu}>
                <img src={webCmsuRed} alt="web cmsu"/>
            </div>
        </section>
    );
}