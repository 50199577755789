import {useNavigate} from "react-router-dom";
import {PathNames} from "../../../navigation/pathNames";
import styles from '../styles/Main.module.css';
import {useMainStore} from "../store/mainStore";
import {useEffect} from "react";
import {NewsGridSection} from "../../News/modules/NewsGridSection";
import {useSelectedNewsStore} from "../../News/store/newsStore";



export const NewsSection = () => {
    const navigate = useNavigate();
    const {news, fetchNews} = useMainStore();
    const {  setSelectedNews } = useSelectedNewsStore();

    const reverseNews = (news) => {
        // console.log("news",JSON.stringify(news, null ,3))
        return news.slice().reverse();
    }

    useEffect(() => {
            (async () => {
                await fetchNews()
                // console.log()
            })();

    }, []);

    return (
        <>
            {/*{JSON.stringify(reverseNews(news), null, 3)}*/}
                <h1 className={styles.newsTitle}>Новини</h1>
                <NewsGridSection news={reverseNews(news).slice(0, 6)} loading={false}/>
                <button
                    className={styles.newsBtn}
                    onClick={() => {
                        setSelectedNews(null)
                        navigate(PathNames.News)
                }}>
                    Більше новин
                </button>
        </>
    )
}
