import React from "react";
import {PathNames} from "../../../navigation/pathNames";
import businessCenter from '../../../assets/modern-business-building-scenery-touching-sky.png'
import businessTeam from '../../../assets/business-team-manager-meeting.png'
import businessMeeting from '../../../assets/business-team-manager-meeting2.png'
import {useNavigate} from "react-router-dom";
import styles from '../styles/Main.module.css'
import AOS from "aos";
AOS.init({
    duration: 200,
})
function AboutUsSection() {
    const navigate = useNavigate()

    return (
        <>
            <section className={styles.containerAboutUs}>
                <div className={styles.skyscrapersContainer}>
                    <img
                        className={styles.skyscrapersImg} src={businessCenter} alt="skyscrapers"/>
                </div>
                <div className={styles.aboutUsMiddleSection}>
                    <h1 className={styles.titleMainAboutUs}>Про компанію</h1>
                    <p className={styles.paragraphTextAboutUs}>{"Центр муніципальних систем управління розпочав свій шлях у кінці 2012 року. Від тоді і до сьогодні ЦМСУ знаходиться у постійному русі для вдосконалення “Єдиного Рахунку” та сервісів, пов'язаних з ним. "}</p>
                    <p className={`${styles.paragraphTextAboutUs} ${styles.mobileNone}`}>{"Наше підприємство покликане виконувати функції єдиного розрахункового центру, який консолідує нарахування, прийом та обробку платежів за житлово-комунальні послуги мешканців міста Вінниця по всіх постачальниках житлово-комунальних послуг (всього – 589, в т. ч. 18 – управляючі компанії, 556 – ОСББ, теплопостачальні організації, а також підприємства водопостачання, газопостачання, електропостачання, теплопостачальні організації та інші)."}</p>
                    <div className={styles.containerLinkAboutUs}>
                        <button className={styles.linkAboutUs} onClick={() => navigate(PathNames.AboutUs)}>
                            Більше про компанію
                        </button>
                    </div>
                    <img
                        className={styles.teamImg} src={businessTeam} alt="good team"/>
                </div>
                <div className={styles.meetingContainer}>
                    <img
                        className={styles.meetingImg} src={businessMeeting} alt='company'/>
                </div>
            </section>
            <section className={styles.aboutUsSectionMobile}>

            </section>
        </>
    )
}

export const MemoizedAboutUs = React.memo(AboutUsSection)
