import styles from "./Header.module.css";
import {Link} from "react-router-dom";
import {PathNames} from "../../navigation/pathNames";
import React, {useState} from "react";
import logo from '../../assets/logo.png'
import {DropdownMenu} from "../DropDown/DropDown";
import facebook from "../../assets/Vector.svg";
import linkedin from "../../assets/free-icon-linkedin-logo-61109 2.svg";
import {useSelectedNewsStore} from "../../pages/News/store/newsStore";

export const Header = ({isBorderShow,setIsButtonClicked,setActiveCard}) => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobileDropdownVisible, setIsMobileDropdownVisible] = useState(false)
    const { setSelectedNews } = useSelectedNewsStore();

    const resetCareers = ()=>{
        if(setIsButtonClicked){
            setIsButtonClicked(false)
            setActiveCard(false)
        }
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMouseEnter = () => {
        setDropdownVisible(prevState => !prevState);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(prevState => !prevState);
    };

    return (
        <header id="header" className={`${styles.header} ${isBorderShow ? styles.border : styles.borderMainPage}`}>
                <div className={styles.mobileContinerHeader}>
                    <div className={styles.logoMobile}>

                        <Link to={PathNames.Main} onClick={()=>setSelectedNews(null)}>
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                    <div className={styles.burgerMenu} onClick={toggleMenu}>
                        <div className={`${!isMenuOpen ? styles.open : styles.bar}`}></div>
                        <div className={` ${!isMenuOpen ? styles.open : styles.bar}`}></div>
                        <div className={` ${!isMenuOpen ? styles.open : styles.bar}`}></div>
                    </div>
                </div>
                <div className={styles.logo}>
                    <Link to={PathNames.Main} onClick={()=>setSelectedNews(null)}>
                        <img src={logo} alt="logo" />
                    </Link>
                </div>
            <a className={`${!isDropdownVisible ? styles.headerLink : styles.headerLinkIsDropdownVisible}`}>
                {isMenuOpen && (<div className={styles.spacer}></div>)}
                <div className={`${isDropdownVisible && styles.dropdownVisible}`}>
                            <div className={styles.mobileStyle} onClick={() => {
                                setIsMobileDropdownVisible(prevState => !prevState)
                            }}>
                                <p className={`${isMobileDropdownVisible && styles.extramargin}
                                 ${!isMobileDropdownVisible && isMenuOpen && styles.dropdownArrow}
                                  ${styles.dropdownUPArrow}
                                   ${!isMenuOpen && styles.displayNone}`}>
                                    Проєкти
                                    {isMobileDropdownVisible &&
                                        <span className={styles.spanUp}></span>}
                                </p>
                                {isMobileDropdownVisible && isMenuOpen &&
                                    <div className={`${styles.drop} ${!isMenuOpen && styles.displayNone}`}
                                        onClick={
                                            () => {
                                                setIsMenuOpen(prevState => !prevState)
                                            }
                                        }
                                    >
                                        <DropdownMenu/>
                                    </div>}
                            </div>
                            <div className={styles.webStyle}>
                            <div onMouseEnter={handleMouseEnter}
                                 onMouseLeave={handleMouseLeave}
                            >
                                <p className={`${isDropdownVisible && styles.extramargin} ${styles.dropdownArrow}`}>Проєкти</p>
                                {isDropdownVisible && <div className={styles.drop}><DropdownMenu/></div>}
                            </div>
                            </div>

                </div>
            </a>

            <Link to={PathNames.Services} className={styles.headerLink}>
                <div>
                    <p className={!isMenuOpen && styles.displayNone}>Послуги</p>
                </div>
            </Link>

            <Link to={PathNames.AboutUs} className={`${styles.headerLink} ${styles.headerLinkExtrawidth}`}>
                <div>
                    <p className={!isMenuOpen && styles.displayNone}>Про компанію</p>
                </div>
            </Link>

            <Link to={PathNames.News} className={styles.headerLink} onClick={()=>setSelectedNews(null)}>
                <div>
                    <p className={!isMenuOpen && styles.displayNone}>Новини</p>
                </div>
            </Link>

            <Link to={PathNames.Careers} className={styles.headerLink}>
                <div onClick={resetCareers}  className={styles.vacancyHeader}>
                    <p className={!isMenuOpen && styles.displayNone}>Вакансії</p>
                </div>
            </Link>

            <button className={styles.switchAccessibility}>
                    <span>
                        {/*eye icon*/}
                    </span>
                <span>
                    </span>
            </button>

            <select
                disabled={true}
                className={styles.switchLang}>
                <option value="UA">UA</option>
                <option value="EN">EN</option>
            </select>
<div className={styles.mobileStyle}>
            <div className={`${isMenuOpen ? styles.smHeaderContainer : styles.displayNone}`} >
                <select
                    disabled={true}
                    className={styles.switchLangMobile}>
                    <option value="UA">UA</option>
                    <option value="EN">EN</option>
                </select>
                <div className={styles.socialMedeaContainer}>
                    <a href="https://www.facebook.com/cmsm.ua" target="_blank" rel="noreferrer">
                        <img src={facebook} alt={"facebook-logo"}/>
                    </a>
                    <a href="https://www.linkedin.com/company/%D1%86%D0%B5%D0%BD%D1%82%D1%80-%D0%BC%D1%83%D0%BD%D1%96%D1%86%D0%B8%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B8%D1%85-%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC-%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D1%96%D0%BD%D0%BD%D1%8F/"
                       target="_blank" rel="noreferrer">
                        <img src={linkedin} alt={"linkedin-logo"}/>
                    </a>
                </div>
            </div>
</div>
        </header>
    )
}
