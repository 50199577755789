import {CarouselItem} from "./CarouselItem";
import Carousel from "react-multi-carousel";
import styles from "../../styles/Projects.module.css";
import {ButtonGroup} from "../../../Main/components/ServicesSlider/ButtonGroup";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


const mockData = [
    {
        id:1,
        feedback: '“Я хотів би висловити свою щиру вдячність за професіональну роботу. Працюючи з вами, я отримав цінні знання і інструменти для ефективної роботи в цій галузі.”',
        name: "Петро Філатов",
        job: 'Директор ТОВ ”Енера”'
    },
    {
        id:2,
        feedback: '“Я хотів би висловити свою щиру вдячність за професіональну роботу. Працюючи з вами, я отримав цінні знання і інструменти для ефективної роботи в цій галузі.”',
        name: "Петро Філатов",
        job: 'Директор ТОВ ”Енера”'
    },
    {
        id:3,
        feedback: '“Я хотів би висловити свою щиру вдячність за професіональну роботу. Працюючи з вами, я отримав цінні знання і інструменти для ефективної роботи в цій галузі.”',
        name: "Петро Філатов",
        job: 'Директор ТОВ ”Енера”'
    },
    {
        id:4,
        feedback: '“Я хотів би висловити свою щиру вдячність за професіональну роботу. Працюючи з вами, я отримав цінні знання і інструменти для ефективної роботи в цій галузі.”',
        name: "Петро Філатов",
        job: 'Директор ТОВ ”Енера”'
    },
    {
        id:5,
        feedback: '“Я хотів би висловити свою щиру вдячність за професіональну роботу. Працюючи з вами, я отримав цінні знання і інструменти для ефективної роботи в цій галузі.”',
        name: "Петро Філатов",
        job: 'Директор ТОВ ”Енера”'
    },
    {
        id:6,
        feedback: '“Я хотів би висловити свою щиру вдячність за професіональну роботу. Працюючи з вами, я отримав цінні знання і інструменти для ефективної роботи в цій галузі.”',
        name: "Петро Філатов",
        job: 'Директор ТОВ ”Енера”'
    },
    {
        id:7,
        feedback: '“Я хотів би висловити свою щиру вдячність за професіональну роботу. Працюючи з вами, я отримав цінні знання і інструменти для ефективної роботи в цій галузі.”',
        name: "Петро Філатов",
        job: 'Директор ТОВ ”Енера”'
    },
]
export const FeedbackSlider = (props) => {
    const renderItems = mockData.map((item, index) => {
        return <CarouselItem
            key={index}
            feedback={item.feedback}
            ownerName={item.name}
            job={item.job}
        />
    })
    return (
        <section>
            <h2 className={styles.feedbackSliderTitle}>Відгуки</h2>

    <Carousel
        responsive={responsive}
        containerClass={styles.containerCarousel}
        dotListClass=""
        draggable
        focusOnSelect={false}
        arrows={false}
        itemClass={styles.containerItem}
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderDotsOutside={false}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        slidesToSlide={1}
        swipeable
        customButtonGroup={<ButtonGroup isMore lastIndex={mockData.length - 4}/>}
        renderButtonGroupOutside={true}
    >
        {mockData ? renderItems : <h1>Sorry</h1>}
    </Carousel>

        </section>
    )
}
