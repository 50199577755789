import React, {useState} from "react";

import {CareersSection} from "./modules/CareersSection";
import {Header} from "../../components/Header/Header";
import {Footer} from "../../components/Footer/Footer";
import {NavigationTop} from "../../components/NavigationTop/NavigationTop";
import styles from "../Careers/styles/Careers.module.css"
import {ToastContainer} from "react-toastify";

export const Careers = () => {
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [activeCard, setActiveCard] = useState(null);
    return (
        <section className={styles.container}>
            <ToastContainer/>
            <div className={styles.headerContainer}>
                <Header setActiveCard={setActiveCard} activeCard={activeCard} setIsButtonClicked={setIsButtonClicked} isBorderShow/>
            </div>
            <div className={styles.navigationTopContainer}>
            <NavigationTop text={'Вакансії'}/>
            </div>
                <CareersSection setActiveCard={setActiveCard} activeCard={activeCard} isButtonClicked={isButtonClicked} setIsButtonClicked={setIsButtonClicked}/>
            <Footer/>
        </section>
    )
}
