import {NavigationButton} from "../../../components/NavigationButton/NavigationButton";
import er from '../../../assets/er.png'
import styles from "../styles/Projects.module.css"
import React, {useEffect, useState} from "react";
import {Modal} from "../../../components/Modal/Modal";
import {useSelectedOptionStore} from "../../../components/Header/headerStore";
import payImg from "../../../assets/mocupPay2.png"
import proosbb from "../../../assets/proosbb11.png"
import terminal from "../../../assets/terminal.png"
import Pdf from "../../../assets/project_rules.pdf"

export const ProjectsSectionHeader = ({projectsType}) => {

    const {selectedOption,setSelectedOption} = useSelectedOptionStore();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);

    };
    useEffect(() => {
        const timeout = setTimeout(() => {
            setSelectedOption('');
        }, 100);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line
    }, [selectedOption]);
    return (
        <section className={styles.projectsSectionHeaderContainer}>
            {isModalOpen && (
                <Modal title={'Обговорити проєкт'} comment={'Коментар'} onClose={closeModal} fromProjects/>
            )}
            {(selectedOption === 'Єдиний Рахунок' || projectsType === 'Єдиний Рахунок') && (
                <>
                    <div className={styles.projectsSectionHeaderDescription}>
                        <div>
                            <h1 className={styles.projectsSectionHeaderTitle}>Проєкт “Єдиний Рахунок”</h1>
                        </div>
                        <div>
                            <p className={styles.projectsSectionHeaderParagraph}>
                                "Єдиний Рахунок" — це наш інноваційний підхід до спрощення та оптимізації
                                інформованості та оплати житлово-комунальних та інших послуг споживачів.
                                Також це прості та прозорі умови співпраці в єдиному інформаційному
                                просторі всіх учасників Проєкту.
                            </p>
                        </div>

                        <NavigationButton onClick={openModal} extraClass={styles.navButton}
                                          buttonText={'Обговорити проєкт'}/>

                        <div className={styles.rulesContainer}>
                            <span className={styles.rules}> <a href={Pdf} target="_blank" rel="noreferrer">Правила проєкту</a></span>
                        </div>
                    </div>
                    <div className={styles.headerImageContainer}>
                        <img className={styles.headerImage} src={er} alt="er"/>
                    </div>
                </>
            )
            }

            {(selectedOption === 'proOSBB' || projectsType === 'proOSBB') && (
                <>
                    <div className={styles.projectsSectionHeaderDescription}>
                        <div>
                            <h1 className={styles.projectsSectionHeaderTitle}>Проєкт proOSBB</h1>
                        </div>
                        <div>
                            <p className={`${styles.projectsSectionHeaderParagraph} ${styles.projectsSectionHeaderParagraphPay}`}>
                                proOSBB – це інноваційний інструмент для ОСББ, який допомагає ефективно управляти та
                                оптимізувати всі процеси у будинку. Доступність в будь-який час: наша програмна
                                платформа доступна онлайн, щоб Ви могли контролювати всі ваші будинки з будь-якого
                                місця.
                            </p>
                        </div>
                        <div>
                            <NavigationButton onClick={openModal} extraClass={styles.navButton}
                                              buttonText={'Обговорити проєкт'}/>
                        </div>
                        {/*<div className={styles.rulesContainer} >*/}
                        {/*    <img className={styles.iconDocument} src={iconDocument} alt="iconDocument"/>  <span className={styles.rules}> <a href = {Pdf} target = "_blank" rel="noreferrer">Правила проєкту</a></span>*/}
                        {/*</div>*/}
                    </div>
                    <div className={`${styles.headerImageContainer} ${styles.headerImageContainerOSbb}`}>
                        <img className={`${styles.headerImage} ${styles.headerImageProosbb}`} src={proosbb} alt="proosbb"/>
                    </div>
                </>
            )
            }

            {(selectedOption === 'pay' || projectsType === 'pay') && (
                <>
                    <div className={styles.projectsSectionHeaderDescription}>
                        <div>
                            <h1 className={styles.projectsSectionHeaderTitle}>Сервіс Pay</h1>
                        </div>
                        <div>
                            <p className={`${styles.projectsSectionHeaderParagraph} ${styles.projectsSectionHeaderParagraphPay}`}>
                                Сервіс, де кожен платіж стає миттєвим та безпечним досвідом.
                                <p> "Pay" — це не
                                    лише зручний інструмент для оплати, але й комплексний сервіс, який спрощує
                                    та полегшує фінансові операції.</p>

                            </p>
                        </div>
                        <div>
                            <NavigationButton onClick={openModal} extraClass={styles.navButton}
                                              buttonText={'Обговорити проєкт'}/>
                        </div>
                        {/*<div className={styles.rulesContainer} >*/}
                        {/*    <img className={styles.iconDocument} src={iconDocument} alt="iconDocument"/>  <span className={styles.rules}> <a href = {Pdf} target = "_blank" rel="noreferrer">Правила проєкту</a></span>*/}
                        {/*</div>*/}
                    </div>
                    <div className={styles.headerImagePayContainer}>
                        <img className={`${styles.headerImage} ${styles.headerImagePay}`} src={payImg} alt="pay"/>
                    </div>
                </>
            )
            }
            {(selectedOption === 'terminal' || projectsType === 'terminal') && (
                <>
                    <div className={styles.projectsSectionHeaderDescription}>
                        <div>
                            <h1 className={`${styles.projectsSectionHeaderTitle} ${styles.projectsSectionHeaderTitleTerminal}`}>Проєкт
                                “Термінал мережа + безготівкова оплата”</h1>
                        </div>
                        <div>
                            <p className={`${styles.projectsSectionHeaderParagraph} ${styles.projectsSectionHeaderParagraphPay}`}>
                                Плати зручно та безпечно. З допомогою нашої розгалуженої «Pay Термінал мережі» можна
                                здійснювати оплату в готівковій та безготівковій формі.
                            </p>
                        </div>
                        <div className={styles.navBtnContainer}>
                            <NavigationButton onClick={openModal} extraClass={styles.navButton}
                                              buttonText={'Обговорити проєкт'}/>
                        </div>
                        {/*<div className={styles.rulesContainer} >*/}
                        {/*    <img className={styles.iconDocument} src={iconDocument} alt="iconDocument"/>  <span className={styles.rules}> <a href = {Pdf} target = "_blank" rel="noreferrer">Правила проєкту</a></span>*/}
                        {/*</div>*/}
                    </div>
                    <div className={`${styles.headerImagePayContainer} ${styles.headerImageTerminalContainer}`}>
                        <img className={styles.headerImageTerminal} src={terminal} alt="terminal"/>
                    </div>
                </>
            )
            }
        </section>
    )
}



