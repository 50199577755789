import styles from '../styles/Main.module.css'
import Lottie from "lottie-react";
import cmsu from "../assets/ЦМСУ.json";
import AOS from "aos";
import React, {Fragment, useEffect, useRef, useState} from "react";
import CountUp from "react-countup";
AOS.init({
    mirror: true,
});

export const StatisticSection = () => {
    const playerWrapperRef = useRef(null);
    const lottieRef = useRef(null);
    const [isStart,setIsStart]=useState(false)

    const AnimatedNumber = ({ finalValue, className }) => {
        return (
            <div
                data-aos="zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="3000"
                data-aos-duration="10000"
                data-aos-once="true"
                data-aos-offset="0"
                data-aos-once-anchor={`${className}`}
                className={className}
            >
               <CountUp start={0} end={finalValue} duration={10} separator={' '} />
            </div>
        );
    };

    useEffect(() => {
        const playerWrapper = playerWrapperRef.current;

        if (!playerWrapper) return;

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: [0, 0.2, 0.45, 1.0],
        };

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (playerWrapperRef.current ) {
                        setIsStart(true)
                        lottieRef.current.play();
                        observer.disconnect();
                    }
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);
        observer.observe(playerWrapper);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <Fragment>
            <h1 className={styles.statisticContainerTitle}>Досвід в цифрах</h1>
            <div className={styles.statisticContainerAnime}>
                    <Lottie
                        lottieRef={lottieRef}
                        animationData={cmsu}
                        autoplay={false}
                        loop={false}
                        renderer="svg"
                        style={{ width: '100%', height: '100%' }}
                    />
                {isStart &&(
                    <>
                        <AnimatedNumber finalValue={589} className={styles.enterprises}/>
                        <AnimatedNumber finalValue={566} className={styles.osbb}/>
                        <AnimatedNumber finalValue={17} className={styles.finance}/>
                        <AnimatedNumber finalValue={158000} className={styles.users}/>
                        <AnimatedNumber finalValue={650000} className={styles.prints}/>
                    </>
            ) }
            <div ref={playerWrapperRef}
                 style={{height: '30%', width: '100%', position: "absolute", bottom: '35%'}}/>
        </div>
</Fragment>
)
}
