import styles from "../../News.module.css";
import {formatArticleText, formattedDate} from "../../../../helpers/helpers";

export const ArticleDetails = ({ article }) => {

    return (
        <div className={styles.fullContainer}>
            <h2 className={styles.fullTitle}>{article.title_blog}</h2>
            <div className={styles.fullMainContent}>
                <div className={styles.fullDescription}>
                    <p className={styles.fullDescriptionText}>{formatArticleText(article.description_blog)}</p>
                </div>
                <div className={styles.fullImg}>
                    <img src={article.image_blog} alt="news_image" />
                </div>
            </div>
            <div className={styles.date}>{formattedDate(article.updated_at)}</div>
        </div>
    );
}