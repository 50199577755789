import styles from './Pagination.module.css'
import {useState} from "react";
import arrowRight from '../../../../assets/FrameArrowNewsRight.png'
import arrowLeft from '../../../../assets/FrameArrowNews.png'


export const Pagination = ({newsPerPage, totalNews, paginate}) => {
    const pageNumbers = [];
    const [activePage, setActivePage] = useState(1);
    const maxNumbersRender = 6

    for (let i = 1; i <= Math.ceil(totalNews / newsPerPage); i++) {
        pageNumbers.push(i)
    }

    const backMoveHandler = () => {
        if (activePage !== 1) {
            paginate(activePage - 1)
            setActivePage(prevState => prevState - 1)
        }
    }
    const forwardMoveHandler = () => {
        if (activePage !== Math.ceil(totalNews / newsPerPage)) {
            paginate(activePage + 1)
            setActivePage(prevState => prevState + 1)
        }
    }

    return (
        <div className={styles.container}>
            <ul className={styles.list}>
                <li className={styles.btn}
                    onClick={backMoveHandler}
                >
                    <img src={arrowLeft} alt="arrowLeft"/> {"Назад"}
                </li>
                {pageNumbers.map((number, index) => {
                    if (index < maxNumbersRender) {
                        return (
                            <li key={number} className={`${styles.listItem} ${number === activePage && styles.active}`}>
                                <span onClick={() => {
                                    setActivePage(number)
                                    paginate(number)
                                }}>
                                    {number}
                                </span>
                            </li>
                        )
                    } else {
                        return null
                    }
                })}
                <li className={styles.btn}
                    onClick={forwardMoveHandler}
                >
                    {"вперед"} <img src={arrowRight} alt="arrowRight"/>
                </li>
            </ul>
        </div>
    )
}
