import styles from './News.module.css'
import {formattedDate, truncateText} from "../../../../helpers/helpers";

export const NewsCard = (props) => {
    const {description, date, img, onClick} = props;

    return (
        <article onClick={onClick} className={styles.cardContainer}>
            <div className={styles.imageContainer}>
                <img id={styles.cardImgId}
                    className={styles.cardImg}
                    src={img} alt="news_image"/>
            </div>
            <div className={styles.cardDescription}>
                <p>{truncateText(description)}</p>
            </div>
            <aside className={styles.date}>{formattedDate(date)}</aside>
            <span className={styles.borderBottom}></span>
        </article>
    )
}
