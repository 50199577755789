import React, {useEffect, useState} from "react";
import {Header} from "../../components/Header/Header";
import {Footer} from "../../components/Footer/Footer";
import {Link} from "react-router-dom";

import styles from "../Projects/styles/Projects.module.css"
import {ProjectsSectionHeader} from "./modules/ProjectsSectionHeader";
import {ProjectsSectionMain} from "./modules/ProjectsSectionMain";
import {AdditionalNavigation} from "../../components/AdditionalNavigation/AdditionalNavigation";
import {FeedbackSlider} from "./modules/FeedbackSlider/FeedbackSlider";
import {Modal} from "../../components/Modal/Modal";

export const Projects = ({projectsType}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);

    };
    return (
        <section className={styles.container}>
            <div className={styles.headerContainer}>
                <Header isBorderShow/>
            </div>
            {
                isModalOpen && <Modal title={'Зв’язатися'} onClose={closeModal} comment={'Коментар'}/>
            }
            <div className={styles.navigationTopContainer}>
                <Link to={'/'} className={styles.link}>
                    <div>
                        <h4 className={styles.title}>Головна</h4>
                    </div>
                </Link>
                <ProjectsSectionHeader projectsType={projectsType}/>
            </div>
            <ProjectsSectionMain projectsType={projectsType}/>
            <div className={styles.sliderContainer}>
                <FeedbackSlider/>
            </div>
            <div  className={styles.addNavigation}>
                <AdditionalNavigation
                    onClick={() =>openModal()}
                    buttonText={"Зв’язатись"}
                    titleText={"Якщо ви бажаєте дізнатись більше"}
                    spanText={"інформації, зв’яжіться з нашим менеджером"}/>
            </div>

            <Footer/>
        </section>
    )
}
