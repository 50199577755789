import styles from "../News.module.css";
import {NewsCard} from "../components/NewsCard";
import React, {useEffect, useRef} from "react";
import {ArticleDetails} from "../components/ArticleDetails/ArticleDetails";
import {useSelectedNewsStore} from "../store/newsStore";
import {useLocation, useNavigate} from "react-router-dom";
import {PathNames} from "../../../navigation/pathNames";

export const NewsGridSection = ({news}) => {
    const navigate = useNavigate();
    const { selectedNews, setSelectedNews } = useSelectedNewsStore();
    const articleDetailsRef = useRef(null);
    const location = useLocation();
    const queryString = location.pathname;
    // const reversedNews = news.slice().reverse();
    const openArticle = (article) => {
        navigate(`${PathNames.News}${article.id}`)
        setSelectedNews(article);
    };

    useEffect(() => {
        if (selectedNews && articleDetailsRef.current) {
            articleDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
        }else{
            setSelectedNews(null)
        }
        if(!queryString.includes('news')){
            setSelectedNews(null)
        }
    }, [selectedNews]);


  return (
      <section className={styles.newsSection}>
          {selectedNews && (
              <div className={styles.articleDetailsRef} ref={articleDetailsRef}>
                  <ArticleDetails article={selectedNews}/>
              </div>
          )}
          <div className={styles.newsGrid}>
              {
                  news ? news.map((item) => {
                      return <NewsCard
                          key={item.id}
                          description={item.description_blog}
                          date={item.updated_at}
                          img={item.image_blog}
                          onClick={()=>openArticle(item)}
                      />
                  }) : null
              }
          </div>

      </section>
  )
}
