import {createBrowserRouter} from "react-router-dom";
import React from "react";
import {News} from "../pages/News";
import {Main} from "../pages/Main";
import {MemoizedAboutUs} from "../pages/AboutUs";
import {Careers} from "../pages/Careers";
import {Projects} from "../pages/Projects";
import {Services} from "../pages/Services";
import {PathNames} from "./pathNames";

export const router = createBrowserRouter([
    {
        path: PathNames.Main,
        element: <Main />
    },
    {
        path: PathNames.News,
        element: <News />
    },
    {
        path: '/:id',
        element: <News />
    },
    {
        path: PathNames.AboutUs,
        element: <MemoizedAboutUs />
    },
    {
        path: PathNames.Careers,
        element: <Careers />
    },
    {
        path: PathNames.Projects,
        children: [
            {
                path: 'Єдиний Рахунок',
                element: <Projects projectsType="Єдиний Рахунок" />
            },
            {
                path: 'pay',
                element: <Projects projectsType="pay" />
            },
            {
                path: 'proOSBB',
                element: <Projects projectsType="proOSBB" />
            },
            {
                path: 'terminal',
                element: <Projects projectsType="terminal" />
            }
        ]
    },
    {
        path: PathNames.Services,
        element: <Services />
    },
])
