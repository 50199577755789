import React from 'react';

import {NavigationButton} from "../../../components/NavigationButton/NavigationButton"
import styles from '../styles/Careers.module.css'

export const CareersCard = ({
                                id,
                                title_vacancy,
                                short_description_vacancy,
                                description_vacancy,
                                requirements,
                                responsibilities,
                                city_name,
                                type_vacancy_id,
                                type_vacancy,
                                onClick,
                                techButton,
                                isActive
                            }) => {


    const handleAdditionalDetails = () => {
        const vacancyDetails = {
            id,
            title_vacancy,
            short_description_vacancy,
            description_vacancy,
            requirements,
            responsibilities,
            city_name,
            type_vacancy_id,
            type_vacancy,
            onClick,
            techButton
        };

        onClick(vacancyDetails);
    };

    return (
        <div className={`${styles.careersCardContainer} ${isActive && styles.activeBorder}`}>
            <div className={styles.descriptionContainer}>
                <h4 className={styles.titleVacancy}>{title_vacancy}</h4>
                <div className={styles.typeVacancy}>
                    <span>{city_name}</span> <span className={styles.redLine}></span><span>{type_vacancy}</span>
                </div>
                <div className={styles.shortDescription}>
                    <p>
                        {short_description_vacancy}
                    </p>
                </div>
            </div>
            <div className={styles.careersCardFooterContainer}>
                <div className={styles.techButton}>
                    {techButton}
                </div>
                <div>
                        <NavigationButton onClick={handleAdditionalDetails}
                                          extraClass={`${styles.navigationButton} ${isActive ? styles.none : styles.block}`}
                                          buttonText={'Детальніше'}/>
                </div>
            </div>
        </div>
    )
}

