import React, {useEffect, useState} from 'react';

import {CareersCard} from "./CareersCard";
import {NavigationButton} from "../../../components/NavigationButton/NavigationButton";
import styles from '../styles/Careers.module.css'
import {Modal} from "../../../components/Modal/Modal";
import {useCareersStore} from "../store/careersStore";
import {formatArticleText} from "../../../helpers/helpers";
import arrow from "../../../assets/FrameArrowNews.png"
import {NoVacancy} from "./NoVacancy";

export const CareersSection = ({setIsButtonClicked,isButtonClicked,setActiveCard,activeCard}) => {
    const {vacancies, fetchVacancies} = useCareersStore();
    const [vacancy, setVacancy] = useState(null)
    const [animationActive, setAnimationActive] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        (async () => {
            await fetchVacancies()
        })();
    }, [fetchVacancies]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);

    };
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const handleButtonClick = (card) => {
        if (card) {
            setIsButtonClicked(card);
            setVacancy(card)
            setActiveCard(card.id);
        }
        if(card.id === activeCard && isButtonClicked){
            setIsButtonClicked(false);
        }
    };

    const careersCards = vacancies.map(({
                                            id,
                                            city_name,
                                            title_vacancy,
                                            type_vacancy,
                                            description_vacancy,
                                            requirements,
                                            responsibilities,
                                            short_description_vacancy
                                        }, index) => (

        <CareersCard
            id={id}
            city_name={city_name}
            title_vacancy={title_vacancy}
            type_vacancy={type_vacancy}
            description_vacancy={description_vacancy}
            requirements={requirements}
            responsibilities={responsibilities}
            short_description_vacancy={short_description_vacancy}
            isActive={activeCard === id}
            onClick={handleButtonClick}
            techButton={title_vacancy.split(' ')[0].toLowerCase()}
            key={index}
            vacancy={vacancy}/>
    ));

    useEffect(() => {
        setAnimationActive(true)
        const timeout = setTimeout(() => {
            setAnimationActive(false)
        }, 500)
        return () => clearTimeout(timeout)
    }, [activeCard])

    return (
        <div
            className={`${styles.careersMainContainer}  ${animationActive ? styles.animate : ''} ${!activeCard && styles.detailedOpen}`}>
            {
                isModalOpen && <Modal title={'Відгук на вакансію'} isCareer onClose={closeModal} comment={'Прикріпити файл з резюме'}/>
            }
            {careersCards.length > 0 ?
                <section
                className={`${styles.careersContainer}  ${isButtonClicked && styles.isAdditionalInfoOpen} ${isButtonClicked && styles.adaptive}`}>
                {careersCards}
            </section> : <NoVacancy openModal={openModal}/>}

            {isButtonClicked &&
                <div className={styles.detailedCareer}>
                    <button
                        className={styles.backButton}
                        onClick={() => {
                            setIsButtonClicked(false)
                        }
                        }
                    ><img src={arrow} alt=""/></button>
                        <header className={styles.detailedCareerHeaderInfo}>
                            <div className={styles.headerDescription}>
                                <h2 className={styles.headerMainTitle}>{vacancy.title_vacancy}</h2>
                                <span className={styles.typeVacancy}>{vacancy.city_name}</span><span
                                className={styles.redLine}></span><span className={styles.typeVacancy}>{vacancy.type_vacancy}</span>
                            </div>
                            <div className={styles.buttonHeader}>
                                <NavigationButton onClick={()=>openModal()} extraClass={styles.navigationButton}
                                                  buttonText={'Подати заявку'}/>
                            </div>
                        </header>
                    <article>
                        <h3 className={styles.jobDescription}>
                            Опис вакансії:
                        </h3>
                        <p className={styles.jobDescriptionParagraph}>
                            {formatArticleText(vacancy.description_vacancy)}
                        </p>
                        <h3 className={styles.requirements}>
                            Вимоги:
                        </h3>
                        <div className={styles.jobDescriptionParagraph}>
                            {formatArticleText(vacancy.requirements)}
                        </div>
                        <h3 className={styles.titleMoreDescript}>
                            Обов'язки:
                        </h3>
                        <div className={styles.jobDescriptionParagraph} >
                            {formatArticleText(vacancy.responsibilities)}
                        </div>
                    </article>
                </div>
            }
        </div>
    )
}
