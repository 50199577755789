import {useNavigate} from "react-router-dom";
import {PathNames} from "../../../../navigation/pathNames";
import styles from './Slider.module.css'

export const ButtonGroup = ({ next, previous, goToSlide, isMore, lastIndex, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    const navigate = useNavigate()
    return (
        <div className={styles.btnGroup}>
            <button
                className={`${styles.more} ${isMore ? styles.hidden : ''}`}
                onClick={() => navigate(PathNames.Services)}>Більше про послуги
            </button>
            <div className={styles.arrowGroup}>
                <button
                    className={`${currentSlide === 0 ? 'disable' : ''} ${currentSlide !== 0 ? styles.btnArrow : styles.disable }`}
                    onClick={() => previous()}>
                    {"<"}
                </button>
                <button
                    className={`${currentSlide === lastIndex ? 'disable' : ''} ${currentSlide !== lastIndex ? styles.btnArrow : styles.disable }`}
                    onClick={() => next()}
                >
                    {">"}
                </button>
            </div>
        </div>
    );
};
