import styles from "../../Projects/styles/Projects.module.css";
import {NavigationButton} from "../../../components/NavigationButton/NavigationButton";
import businessTeam from "../../../assets/business-team-manager-meeting2.png"
import {useSelectedOptionStore} from "../../../components/Header/headerStore";
import {validatePhone} from "../../../helpers/helpers";
import {useState} from "react";
import {SERVER} from "../../../constants/constants";
import {toast, ToastContainer} from "react-toastify";


export const ProjectsSectionMain = ({projectsType}) => {
    const {selectedOption} = useSelectedOptionStore();
    const [phone, setPhone] = useState('')
    const handleFocus = () => {
        if (!phone.startsWith("+380")) {
            setPhone("+380");
        }
    };
    const validateUkrainePhone = (event) => {
        setPhone(validatePhone(event.target.value));

    };

    const handlePostConsultation = () => {
        const data = {
            phone_number: phone,
            form_type: 'consultation'
        }
        if(phone.length >= 16){
            fetch(`${SERVER}/forms`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "*/*",
                    "Accept-Encoding": "gzip, deflate, br",
                    "Connection": "keep-alive"
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(result => {
                    if (result.message) {
                        toast.success('Запит прийнято', {
                            position: "bottom-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            closeButton: false,
                            className: `${styles.toastMessageOK}`,
                        })
                    } else if (result.error) {
                        toast.error(`Error: ${result.error.email[0]}`, {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }else{
            toast.error(`Невірно введені дані`, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                closeButton: false,
                className: `${styles.toastMessage}`,
            });
        }

        setPhone("")
    }

    return (
        <section className={styles.projectsContainer}>
            <nav className={styles.projectsAside}>

                <div>
                    <p className={styles.consultation}>
                        Замовте безкоштовну консультацію – наші експерти підберуть найефективніше рішення
                    </p>
                    <div className={styles.inputContainer}>
                        <input value={phone} onChange={validateUkrainePhone} onFocus={handleFocus}
                               placeholder={'Введіть ваш номер телефону'} className={styles.input} type="tel"/>
                    </div>
                    <NavigationButton
                        onClick={handlePostConsultation}
                        buttonText={'Замовити консультацію'} extraClass={`${styles.navButtonMain} ${styles.orderK}`}/>
                </div>


            </nav>
            {(selectedOption === 'Єдиний Рахунок' || projectsType === 'Єдиний Рахунок') && (
                <article className={styles.projectsArticle}>
                    <p className={styles.articleHeader}>
                        Проект об'єднує споживачів ЖКП, постачальників, банки та органи місцевого
                        самоврядування. Для споживачів - зручний формат отримання інформації та
                        одноразова оплата за всі послуги. Доступність різних способів оплати та
                        економія часу. Передача показників в Єдиному вікні. Для постачальників -
                        економія ресурсів, швидкість отримання коштів, обмін інформацією з
                        Пенсійним фондом. Для банків - доступ до УМБД та спрощення прийому
                        платежів. Для органів самоврядування - консолідована інформація та
                        підвищення рівня оплати через єдиний рахунок.
                    </p>
                    <div className={styles.containerStatistic}>
                        <div className={styles.containerStatisticLeft}>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>1,03 млн ос. рахунків
                                    постачальників</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Нарахування за житлово-комунальні
                                    послуги</p>
                            </div>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>606 тис./міс</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Формування та друк Єдиного
                                    Рахунку, доставка рахунків за допомогою послуг партнерських служб кур’єрської
                                    доставки</p>
                            </div>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>112 тис./ міс помешкань</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Збір показників лічильників</p>
                            </div>
                        </div>

                        <div className={styles.containerStatisticRight}>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>8 Центрів обслуговування
                                    споживачів</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Проведення консультацій в оф –
                                    лайн</p>
                            </div>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>3 тис. /міс</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Кількість проведених
                                    консультацій</p>
                            </div>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>70 терміналів</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Організація прийому платежів через
                                    платіжні термінали</p>
                            </div>
                        </div>

                    </div>
                    <div>
                        <img className={styles.businessTeamimg} src={businessTeam} alt="businessTeam"/>
                    </div>
                </article>)}

            {(selectedOption === 'pay' || projectsType === 'pay') && (
                <article className={styles.projectsArticle}>
                    <p className={styles.articleHeader}>
                        Можливість оплачувати рахунки та керувати своїми фінансами в єдиному цифровому просторі. Ми
                        прагнемо забезпечити вам найвищий рівень зручності та безпеки у кожній транзакції. <a target="_blank" href='https://pay.vn.ua/' rel="noreferrer">Pay</a> – сервіс, який включає в себе web ресурс pay.vn.ua та мобільний додаток Єдиний рахунок.
                        Паралельно з оплатою за ЖКП надається можливість оплати за інші послуги, в т.ч. за дитячий
                        садок, інтернет, телебачення, податки, страхування, та інше.
                    </p>
                    <div className={styles.containerStatistic}>
                        <div className={styles.containerStatisticLeft}>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>158 тис. постійних користувачів</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Кількість користувачів, які
                                    регулярно користуються нашим сервісом.</p>
                            </div>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>50 тис. щомісячних платежів</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Свідчить про довіру та
                                    популярність нашого сервісу для здійснення фінансових операцій.</p>
                            </div>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>593 тис. транзакцій за останній
                                    рік</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Свідчить про активність наших
                                    користувачів та надійність системи.</p>
                            </div>
                        </div>

                        <div className={styles.containerStatisticRight}>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>10 тис. користувачів чат –
                                    ботами</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Взаємодіють з нами через чат-боти,
                                    роблячи обслуговування ще більш ефективним та зручним</p>
                            </div>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>60 тис. завантажень додатку</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Свідчить про зростання
                                    популярності в онлайн-середовищі.</p>
                            </div>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>70 терміналів</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Для зручності наших користувачів
                                    ми розглядаємо різні способи оплат</p>
                            </div>
                        </div>

                    </div>
                    <div>
                        <img className={styles.businessTeamimg} src={businessTeam} alt="businessTeam"/>
                    </div>
                    <ToastContainer/>
                </article>)}

            {(selectedOption === 'proOSBB' || projectsType === 'proOSBB') && (
                <article className={styles.projectsArticle}>
                    <p className={styles.articleHeader}>
                        Проєкт <a className={styles.alwaysBlue} target="_blank" href='https://proosbb.com/' rel="noreferrer">proOSBB</a> – це інноваційна
                        онлайн-платформа для ефективного
                        управління багатоквартирними будинками ОСББ. Забезпечує легке ведення
                        обліку, оплат та спілкування між мешканцями, та управителями. Зручність,
                        ефективність та транспарентність управління житловим простором – proOSBB
                        вам у цьому допоможе.
                    </p>
                    <div className={styles.containerStatistic}>
                    <div className={styles.containerStatisticLeft}>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>Ефективне управління</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Оптимізуйте всі аспекти управління
                                    вашим будинком, заощаджуйте час та ресурси завдяки автоматизації роботи, легка
                                    комунікація з мешканцями.</p>
                            </div>
                        <div className={styles.containerDescr}>
                            <h3 className={styles.containerStatisticLeftTitle}>Зручність</h3>
                            <p className={styles.containerStatisticLeftParagraph}>Наше програмне забезпечення легке
                                у використанні, навіть для тих, хто не має технічного досвіду.
                            </p>
                        </div>
                        <div className={styles.containerDescr}>
                            <h3 className={styles.containerStatisticLeftTitle}>Інноваційні рішення</h3>
                            <p className={styles.containerStatisticLeftParagraph}>Завжди оновлюємо сервіс, щоб Ви
                                були на крок попереду.</p>
                        </div>
                    </div>

                        <div className={styles.containerStatisticRight}>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>Безпека даних</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Ми дбаємо про безпеку вашої
                                    інформації, забезпечуючи конфіденційність та надійність</p>
                            </div>
                            <div className={styles.containerDescr}>
                                <h3 className={styles.containerStatisticLeftTitle}>Технічна підтримка</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Наша команда завжди готова надати
                                    вам професійну підтримку і відповісти на всі ваші запитання</p>
                            </div>
                        </div>

                    </div>
                    <div>
                        <img className={styles.businessTeamimg} src={businessTeam} alt="businessTeam"/>
                    </div>
                </article>)}
            {(selectedOption === 'terminal' || projectsType === 'terminal') && (
                <article className={styles.projectsArticle}>
                    <p className={styles.articleHeader}>
                        «Pay Термінал мережа» - це зручний метод здійснення платежів комунальним підприємствам,
                        мобільним операторам, медичним та іншим надавачам послуг в режимі 24/7.
                        Приймайте платежі від клієнтів за допомогою технології QR-коду. Розмістіть QR-код поруч з вашими
                        клієнтами. Оплата без черг та завчасно в безготівковій формі гарантована. Все, що потрібно від
                        платника, – відсканувати згенерований QR-код і провести розрахунок.
                    </p>
                    <div className={styles.containerStatistic}>
                        <div className={styles.containerStatisticLeft}>
                            <div>
                                <h3 className={styles.containerStatisticLeftTitle}>Відповідальність</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Вимогливе ставлення до
                                    зобов’язань.</p>
                            </div>
                            <div>
                                <h3 className={styles.containerStatisticLeftTitle}>Відкритість</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Прозорі стосунки з партнерами
                                    спільних проєктів, відкритість до інновацій, запорука успіху.
                                </p>
                            </div>
                            <div>
                                <h3 className={styles.containerStatisticLeftTitle}>Клієнтоорієнтованість</h3>
                                <p className={styles.containerStatisticLeftParagraph}>Ми цілеспрямовано працюємо заради
                                    задоволення потреб клієнта.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img className={styles.businessTeamimg} src={businessTeam} alt="businessTeam"/>
                    </div>
                </article>)}
            <ToastContainer/>
        </section>
    )
}

