import {ServicesSlider} from "../components/PartnersSlider";
import styles from '../styles/Main.module.css'

export const PartnersSection = () => {
    return (
        <section className={styles.partnersContainer}>
            <div className={styles.partnersTitle}>
                <h1>Партнери</h1>
            </div>
            <article className={styles.partnersSliderWrapper}>
                <ServicesSlider/>
            </article>
        </section>
    )
}
