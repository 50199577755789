import {Link} from "react-router-dom";
import {PathNames} from "../../navigation/pathNames";
import logo from './assets/footer-logo.png';
import styles from './Footer.module.css';
import facebook from './assets/facebook.png';
import linkedin from './assets/linnkedin.png'
import {useSelectedNewsStore} from "../../pages/News/store/newsStore";


export const Footer = () => {
    const {  setSelectedNews } = useSelectedNewsStore();

    return (
      <footer className={styles.container}>
          <div className={styles.logoContainer}>
              <Link to={PathNames.Main} onClick={()=>setSelectedNews(null)}>
                <img src={logo} alt={"alternative-logo"}/>
              </Link>
          </div>
          <div className={styles.menuContainer}>
              <Link to={`${PathNames.ProjectsER}#`}>Проєкти</Link>
              <Link to={`${PathNames.Services}#`}>Послуги</Link>
              <Link to={PathNames.AboutUs}>Про компанію</Link>
              <Link to={PathNames.News} onClick={()=>setSelectedNews(null)}>Новини</Link>
              <Link to={PathNames.Careers}>Вакансії</Link>
          </div>
          <div className={styles.contactsContainer}>
              <p>Контакти</p>
              <p>
                  Україна, 21050,
                  <br/>
                  м. Вінниця вул. Антона Листопада, 7а
                  <br/>
                  <a className={styles.contactA} href="tel:+380432651155">(0432) 65-11-55</a>
                      <br/>
                  <a className={styles.contactA} href="mailto:info@cmsm.com.ua">info@cmsm.com.ua</a>
              </p>
              <p>Зворотний зв’язок</p>
          </div>
          <div className={styles.smContainer}>
              <a className={styles.facebook} href="https://www.facebook.com/cmsm.ua" target="_blank" rel="noreferrer">
                <img src={facebook} alt={"facebook-logo"} />
              </a>
              <a href="https://www.linkedin.com/company/%D1%86%D0%B5%D0%BD%D1%82%D1%80-%D0%BC%D1%83%D0%BD%D1%96%D1%86%D0%B8%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B8%D1%85-%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC-%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D1%96%D0%BD%D0%BD%D1%8F/" target="_blank" rel="noreferrer">
                <img src={linkedin} alt={"linkedin-logo"}/>
              </a>
          </div>
      </footer>
  )
}
