export const PathNames = {
    Main: '/',
    News: '/news',
    AboutUs: '/about-us',
    Careers: '/careers',
    Projects: '/projects',
    ProjectsProOsbb:'/projects/proOSBB',
    ProjectsPay: '/projects/pay',
    ProjectsER:'/projects/Єдиний Рахунок',
    ProjectsTerminal:'/projects/terminal',
    Services: '/services'
}
