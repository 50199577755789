import styles from "../../styles/Projects.module.css";

export const CarouselItem = (props) => {
    const {feedback,ownerName,job} = props;

    return (
        <div className={styles.containerCarousel}>
            <div className={styles.titleContainer}>
                <h3 className={styles.itemTitle}>{feedback}</h3>
            </div>
            <div className={styles.descriptionContainer}>
                <p className={styles.itemDescription}>{ownerName}</p>
                <p className={styles.itemJob}>{job}</p>
            </div>
        </div>
    )
}
