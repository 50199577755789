import {createPortal} from "react-dom";
import styles from "./Modal.module.css";
import upload from '../../assets/upload-cloud-02.png'
import React, {useState} from "react";
import documentImg from '../../assets/document-text.png'
import {SERVER} from "../../constants/constants";
import {toast, ToastContainer} from "react-toastify";
import {validatePhone} from "../../helpers/helpers";

export const Modal = ({onClose, children, title, comment, isCareer, fromProjects=false}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const [projectsForm, setProjectsForm] = useState({
        name: '',
        number: '',
    })
    const [commentForm, setCommentForm] = useState("");

    const handleFocus = () => {
        if (!projectsForm.number.startsWith("+380")) {
            setProjectsForm((prevState) => ({
                ...prevState,
                number: "+380",
            }));
        }
    };

    const handleCloseModal = (event) => {
        if (event.target.classList.contains(styles.modalOverlay)) {
            onClose();
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(JSON.stringify(event.target.files, null, 3));
        setSelectedFile(file);
    };

    const nameHandler = (e) => {
        setProjectsForm((prevState) => ({
            ...prevState,
            name: e.target.value,

        }))
    }

    const numberHandler = (e) => {
        setProjectsForm(prevState => ({
            ...prevState,
            number: validatePhone(e.target.value)
        }))
    }

    const commentHandler = e => setCommentForm(e.target.value);

    const clearForm = () => {
        setProjectsForm({
            name: "",
            number: "",
        })
        setCommentForm("")
    }

    const submitProject = () => {
        console.log('submitProject invoked');
        console.log('projectsForm', projectsForm);
        console.log('selectedFile', selectedFile);
        console.log('isCareer', isCareer);
        const formData = new FormData();
        if (!isCareer) {
            const req = {
                name: projectsForm.name,
                phone_number: projectsForm.number,
                message: commentForm,
                form_type: 'discuss',
            }
            formData.append('req', JSON.stringify(req));
        } else {
            const req = {
                name: projectsForm.name,
                phone_number: projectsForm.number,
                form_type: isCareer ? 'vacancy' : 'discuss',
            }
            formData.append('req', JSON.stringify(req));
            formData.append('resume', selectedFile);
        }
        if(projectsForm.number.length >= 16 && projectsForm.name.length >= 1){

            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${SERVER}/forms`);
            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percent = (event.loaded / event.total) * 100;
                    setUploadProgress(percent);
                }
            };

            xhr.onload = () => {
                console.log('xhr.onload invoked');
                if (xhr.status === 200) {
                    const result = JSON.parse(xhr.responseText);
                    if (result.message) {
                        toast.success('Запит прийнято', {
                            position: "bottom-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            closeButton: false,
                            className: `${styles.toastMessageOK}`,
                        })
                        onClose();
                    }
                } else {
                    toast.error(`Невірно введені дані`, {
                        position: "bottom-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        closeButton: false,
                        className: `${styles.toastMessage}`,
                    });
                }
                onClose();
            };

            xhr.onerror = (error) => {
                console.error('Error:', error);
                onClose();
            };

            xhr.send(formData);
        } else {
            toast.error(`Невірно введені дані`, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                closeButton: false,
                className: `${styles.toastMessage}`,
            });
        }
        clearForm();
    };


    return createPortal(
        <div className={styles.modalOverlay} onClick={handleCloseModal}>
            <ToastContainer/>
            <div className={styles.modal}>
                <div className={styles.modalOverlay}>
                    <div className={styles.modal}>
                        <div className={styles.modalContent}>
                            <h2 className={styles.modalTitle}>{title}</h2>
                            <div className={styles.inputGroup}>
                                <label htmlFor="nameInput">Ім’я</label>
                                <input type="text" id="nameInput" placeholder="Введіть ваше ім’я"
                                       className={styles.inputField}
                                       value={projectsForm.name}
                                       onChange={nameHandler}
                                />
                            </div>
                            <div className={styles.inputGroup}>
                                <label htmlFor="phoneInput">Номер телефону</label>
                                <input type="tel"
                                       id="phoneInput"
                                       placeholder="Введіть ваш номер телефону"
                                       className={styles.inputField}
                                       value={projectsForm.number}
                                       onFocus={handleFocus}
                                       onChange={numberHandler}
                                />
                            </div>
                            <div className={styles.inputGroup}>
                                <label htmlFor="commentInput">{comment}</label>
                                {!isCareer ?
                                    <textarea rows={7} id="commentInput" aria-label="Коментар"
                                              placeholder="Введіть ваш коментар" className={styles.textareaField}
                                              value={commentForm}
                                              onChange={commentHandler}
                                    />
                                    :
                                    <div className={styles.upload}>

                                        <div className={styles.containerImg}>
                                            <img src={upload} alt="upload"/>
                                        </div>
                                        <div className={styles.containerText}>
                                            <input type="file" name="file" id="file" 
                                                    className={styles.hiddenInput}
                                                    // accept=".pdf,.doc,.docx"
                                                   onChange={handleFileChange}/>
                                            {selectedFile && <div className={styles.uploadBox}>
                                                <div>
                                                    <img className={styles.documentImg} src={documentImg}
                                                         alt="documentImg"/>
                                                </div>
                                                <div className={styles.selectedName}>
                                                    {selectedFile.name}
                                                   <progress value={uploadProgress} className={styles.customProgress} />
                                                </div>
                                                <div className={styles.prosent}>
                                                    {uploadProgress}%
                                                </div>
                                            </div>}
                                            {!selectedFile &&
                                                <>
                                            <label className={styles.clickUpload} htmlFor="file"> Click to
                                                upload</label>
                                                    <span className={styles.cv}>or drag and drop</span> <span className={styles.cvSecond}> your CV file </span>
                                                </>}
                                        </div>
                                    </div>
                                    
                                }

                            </div>
                            {!isCareer ?
                               <div className={styles.buttonContainer}>
                                    <button 
                                        onClick={submitProject} 
                                        className={`${styles.submitButton} ${projectsForm.number.length >= 16 && projectsForm.name.length >= 1 && commentForm.length > 1 && styles.allGood}`}
                                        >
                                            Надіслати
                                        </button>
                                </div>
                                :
                                <div className={styles.buttonContainer}>                                <button onClick={submitProject} className={`${styles.submitButton} ${projectsForm.number.length >= 16 && projectsForm.name.length >= 1 && styles.allGood}`}>Надіслати</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                ,
                {children}
            </div>
        </div>,
        document.getElementById('modal-root')
    );
};
