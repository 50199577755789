import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './Slider.module.css'

//image imports
import fkmps from './assets/fkmps.png'
import oschadbank from './assets/oschadbank.png'
import teplo_energo from './assets/teplo_energo.png'
import ukrposhta from './assets/ukrposhta.png'
import privatbank from './assets/privatbank.png'
import arrowLeft from './assets/Linkarrowleft.png'
import arrowRight from './assets/LinkarrowRight.png'
import arrowRightRed from './assets/arrowRedr.png'
import arrowLeftRed from './assets/arrowRedl.png'
import enera from './assets/enera.png'
import ukrNafta from './assets/ukrNafta.png'
import vinVoda from './assets/vinOllVodokanal.png'

import {useState} from "react";

const responsive = {
    superLargeDesktop: {
        breakpoint: {max: 4000, min: 3000},
        items: 5
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 5
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 4
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 3
    }
};

const CustomRightArrow = ({onClick}) => {
    const [hovered, setHovered] = useState(false);

    const handleHover = () => {
        setHovered(true);
    };

    const handleMouseOut = () => {
        setHovered(false);
    };

    return <button
        className={styles.arrowRight}
        onClick={() => onClick()}
        onMouseEnter={handleHover}
        onMouseLeave={handleMouseOut}><img src={hovered ? arrowRightRed : arrowRight} alt="arrowRight"/></button>;
};

const CustomLeftArrow = ({onClick}) => {
    const [hovered, setHovered] = useState(false);

    const handleHover = () => {
        setHovered(true);
    };

    const handleMouseOut = () => {
        setHovered(false);
    };
    return <button
        className={styles.arrowLeft}
        onClick={() => onClick()}
        onMouseEnter={handleHover}
        onMouseLeave={handleMouseOut}><img src={hovered ? arrowLeftRed : arrowLeft} alt="arrowLeft"/></button>;
};

export const ServicesSlider = () => {
    return (
        <Carousel
            responsive={responsive}
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass={styles.carouselContainer}
            customLeftArrow={<CustomLeftArrow/>}
            customRightArrow={<CustomRightArrow/>}
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            <a href="http://fkmps.com.ua/o-nas.html" target='_blank' rel="noreferrer"><img className={styles.logo}
                                                                                           src={fkmps} alt={"МПС"}/></a>


            <a href="https://www.oschadbank.ua/" target='_blank' rel="noreferrer"><img className={styles.logo}
                                                                                       src={oschadbank}
                                                                                       alt={"Ощад Банк"}/></a>
            <a href="https://privatbank.ua/udalenniy-banking/privat24" target='_blank' rel="noreferrer"> <img
                className={styles.logo}
                src={privatbank}
                alt={"Приват Банк"}/></a>
            <a href="https://www.ukrposhta.ua/ua" target='_blank' rel="noreferrer"><img
                className={styles.logo} src={ukrposhta} alt={"Укрпошта"}/></a>
            <a href="https://kpvote.com.ua/" target='_blank' rel="noreferrer"> <img
                className={styles.logo} src={teplo_energo} alt={"Вінниця Тепло Енерго"}/></a>
            <a href="https://vin.enera.ua/" target='_blank' rel="noreferrer"><img
                className={styles.logo} src={enera} alt={"Вінниця Єнера"}/></a>
            <a href="https://gas.ua/uk/home" target='_blank' rel="noreferrer"> <img
                className={styles.logo} src={ukrNafta} alt={"нафтогаз"}/></a>
            <a href="https://vinvk.com.ua/" target='_blank' rel="noreferrer"> <img
                className={styles.logo} src={vinVoda} alt={"Вінниця водоканал"}/></a>
        </Carousel>
    )
}
