import React, {useEffect, useState} from "react";
import {Header} from "../../components/Header/Header";
import {Footer} from "../../components/Footer/Footer";
import {ServicesSection} from "./modules/ServicesSection";
import styles from "../Services/styles/Services.module.css";
import {NavigationTop} from "../../components/NavigationTop/NavigationTop";
import {AdditionalNavigation} from "../../components/AdditionalNavigation/AdditionalNavigation";
import {Modal} from "../../components/Modal/Modal";


export const Services = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);

    };
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className={styles.container}>
            {
                isModalOpen && <Modal onClose={closeModal} title={'Зв’язатися'} comment={"Коментар"}/>
            }
            <div className={styles.headerContainer}>
                <Header isBorderShow/>
            </div>
            <div className={styles.navigationTopContainer}>
                <NavigationTop text={'Послуги'}/>
            </div>
            <ServicesSection/>
            <div  className={styles.addNavigation}>
            <AdditionalNavigation
                onClick={() => {
                    openModal()
                }}
                buttonText={"Зв’язатись"}
                titleText={"Якщо ви бажаєте дізнатись більше"}
                spanText={"інформації, зв’яжіться з нашим менеджером"}/>
            </div>
            <Footer/>
        </div>
    )
}
