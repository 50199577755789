import styles from "../styles/Main.module.css";
import {Link} from "react-router-dom";
import {PathNames} from "../../../navigation/pathNames";
import React, {useEffect, useState} from "react";
import {Header} from "../../../components/Header/Header";

import mobileBackground from '../assets/mobileBackground.svg'

export const EntrySection = () => {
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 900);


    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 900);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <section className={styles.entryContainer}>
            {isWideScreen ?<div className={styles.spacer}> <Header/>  </div>: null}
            <article className={styles.content}>
                <h1 className={styles.contentTitle}>
                    Центр <span className={styles.markSpan}>муніципальних</span>
                    <br/>
                    систем управління
                </h1>
                <p className={styles.contentDescription}>
                    Центр надає ряд послуг для постачальників орієнтуючись на світовий досвід та бере участь у інформатизації практично усіх галузей міського господарства
                </p>
                <Link to={PathNames.AboutUs} className={styles.contentBtn}>
                    Детальніше
                </Link>
                <img
                    src={mobileBackground}
                    className={styles.mobileBackground}
                    alt={""}
                />
            </article>
        </section>
    )
 }
