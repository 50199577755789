import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'
import {
    Route,
    RouterProvider, Routes
} from "react-router-dom";
import {router} from "./navigation";
import AOS from 'aos';
import 'aos/dist/aos.css';

// You can also pass an optional settings object
// below listed default settings
AOS.init();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router}>
        <Routes>
            {router.routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                >
                    {route.children && (
                        <Routes>
                            {route.children.map((childRoute, childIndex) => {
                                return(
                                <Route
                                    key={childIndex}
                                    path={childRoute.path}
                                    element={childRoute.element}
                                />
                                )})
                            }
                        </Routes>
                    )}
                </Route>
            ))}
        </Routes>
    </RouterProvider>
);
